import { useAuth } from '@components/Auth';
import { ImageThumbnailRatio } from '@components/ImageThumbnailRatio';
import { useModal } from '@components/ModalHook';
import { MODAL_ID as POPUP_USER_SENT_INFORMATION } from '@components/popup/PopupUserSentInformation';
import VnrLink from '@components/VnrLink';
import { createAnonymousUserData } from '@services/anonymousUserData.service';
import { updateUserProfile } from '@services/user.service';
import { triggerEventUserFillInformation } from '@utils/gtmUtil';
import { localStorageUtil } from '@utils/storageUtil';
import { validateEmail, validatePhoneNumber } from '@utils/validationRules';
import { Button, Checkbox, Col, Form, Input, Row, Select, Tooltip } from 'antd';
import Router, { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { marketOptions, servicesOptions } from 'src/constants';
import { PHONE_CODE } from 'src/constants/phoneCode';
import provinces from 'src/constants/provinces';

import ArrowFlip from './ArrowFlip';
import styles from './Profile.module.scss';
import { INIT_COUNTRY_CODE } from './ProfileInformation';
const imageSignUp = '/images/sign up.jpg';

export const POPUP_ID = 'PROFILE_INFORMATION';

export const serviceNameMapper = {
  SERVICE_01: (
    <span className={styles.optionName}>
      Nhận ngay  <VnrLink to="/airdrop-tai-vnrebates.html">
        VnRebates Airdrop
      </VnrLink> 20$-50$ tiền mặt
    </span>
  ),
  'Khóa Video 1 - VSA & Volume Master (200$)': (
    <span className={styles.optionName}>
      Truy cập FREE - Khóa{' '}
      <VnrLink to="/trading-academy/hoc-dau-tu-forex-nang-cao">"Trading cho Người mới"</VnrLink>
    </span>
  ),
  'Khóa Video 2 - SMC tinh gọn (200$)': (
    <span className={styles.optionName}>
      Voucher 30%{' '}
      <VnrLink to="https://shopee.vn/Combo-05-(SMC-SnD-2.0-ICT-Concept-VSA-Wyckoff-2.0)-5-b%E1%BB%99-th%E1%BA%BB-Flashcard-T%E1%BA%B7ng-khoen-m%C3%B3c-i.1058780730.28910172948?xptdk=f9e69443-a844-46b5-b41f-d99101d7d618">
      Freeship - Flashcard "Trading chuyên sâu"
      </VnrLink>
    </span>
  ),
  'Khóa Video 3 - Supply & Demand 2.0 (200$)': (
    <span className={styles.optionName}>
      Voucher 40% - Khóa học video{' '}
      <Tooltip
        title={
          <ul>
            <li>
              <VnrLink to="/trading-academy-video/khoa-hoc-price-action-volume-master">
                Khóa VSA & Volume Master
              </VnrLink>
            </li>
            <li>
              <VnrLink to="/trading-academy-video/khoa-hoc-smc-tinh-gon">Khóa SMC tinh gọn</VnrLink>
            </li>
            <li>
              <VnrLink to="/trading-academy-video/khoa-hoc-wyckoff-2">Khóa Wyckoff 2.0</VnrLink>
            </li>
            <li>
              <VnrLink to="/trading-academy-video/khoa-hoc-snd-2.0">Khóa Supply & Demand</VnrLink>
            </li>
          </ul>
        }
        overlayClassName={styles.tooltipVideo}
      >
        "Trading chuyên sâu"
      </Tooltip>
    </span>
  ),
  'Dịch vụ Hoàn phí': (
    <span className={styles.optionName}>
      Dịch vụ{' '}
      <VnrLink to="/dich-vu-forex-rebates-hoan-phi-forex-la-gi">Hoàn tiền Forex / Crypto</VnrLink>{' '}
      lên tới 95%
    </span>
  ),
};
const ProfileInformationHomepage = () => {
  const [, showModal] = useModal(POPUP_USER_SENT_INFORMATION);
  const { user } = useAuth();
  const [form] = Form.useForm();
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const userLocalInformationString = localStorageUtil.getItem('user_local_information');
  const userLocalInformation =
    !!userLocalInformationString && userLocalInformationString !== ''
      ? JSON.parse(userLocalInformationString)
      : null;
  const { locale } = useRouter();

  const handleSaveInfo = (e) => {
    e.preventDefault();
    form
      .validateFields()
      .then(async (values) => {
        const { newUserEmail, userName, phone, phoneCode, province, services, market } = values;
        const serviceString = services.join(', ');
        const url = new URL(document.URL);
        const source = url.searchParams?.get('utm_source') || '';
        const campaign = url.searchParams?.get('utm_campaign') || '';
        const content = url.searchParams?.get('utm_content') || '';
        const dataRequest = {
          ...values,
          newUserEmail,
          userName,
          phone,
          phoneCode,
          province,
          services: JSON.stringify(services),
          market: JSON.stringify(market),
          source,
          campaign,
          content,
        };
        try {
          const res = await createAnonymousUserData(dataRequest);
          if (res?.data) {
            showModal({
              text: serviceString
                .replace('SERVICE_01', 'Nhận ngay VnRebates Airdrop 20$-50$ tiền mặt')
                .replace(
                  'Khóa Video 1 - VSA & Volume Master (200$)',
                  'Truy cập FREE - Khóa "Trading cho Người mới"'
                )
                .replace(
                  'Khóa Video 2 - SMC tinh gọn (200$)',
                  'Voucher 30% Freeship - Flashcard "Trading chuyên sâu"'
                )
                .replace(
                  'Khóa Video 3 - Supply & Demand 2.0 (200$)',
                  'Voucher 40% - Khóa học video "Trading chuyên sâu"'
                ),
            });
          }

          Router.push('/');
        } catch (e) {
          console.error(e.response.data.message);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const updateUserInfoFromCache = async (dataRequest) => {
    const res = await updateUserProfile(dataRequest);
    if (res.data) {
      console.log('Update thông tin thành công!');
      triggerEventUserFillInformation;
      localStorageUtil.setItem('user_local_information', '');
    }
  };

  // const InputAddonBefore = ({ text }) => {
  //   return <div className={styles.inputAddonBefore}>{text}</div>;
  // };

  useEffect(() => {
    //trường hợp có data cache + user chưa nhập thông tin services, market + email giống: update thông tin chưa nhập
    if (
      user?.id &&
      userLocalInformation &&
      userLocalInformation !== '' &&
      (!user?.market || user.market === '') &&
      (!user?.services || user.services === '') &&
      userLocalInformation.newUserEmail === user?.email
    ) {
      if (user?.name && user.name !== '') {
        userLocalInformation.userName = user.name;
      }
      if (user?.phone && user.phone !== '') {
        userLocalInformation.phone = user.phone;
      }
      if (user?.province && user.province !== '') {
        userLocalInformation.province = user.province;
      }
      if (user?.market && user.market !== '') {
        userLocalInformation.market = user.market;
      }
      if (user?.services && user.services !== '') {
        userLocalInformation.services = user.services;
      }
      updateUserInfoFromCache(userLocalInformation);
    }

    //init data form
    let services = [];
    let market = [];
    if (user?.services && user?.services !== '') {
      services = JSON.parse(user.services);
    }
    if (user?.market && user?.market !== '') {
      market = JSON.parse(user.market);
    }
    const phoneCode = user?.phoneCode || INIT_COUNTRY_CODE[locale];
    form.setFieldsValue({
      ...user,
      newUserEmail: user.email,
      userName: user.name,
      province: user.province,
      phone: user.phone,
      services,
      market,
      phoneCode,
    });
  }, [user]);

  return (
    <div className={styles.formInHomepage}>
      <div className={styles.guildText}>
        Đăng ký nhận "KHUYẾN MÃI LỚN" dành cho "KHÁCH HÀNG LẦN ĐẦU"
      </div>
      <div className={styles['vnrb-comp-profile-update-info']}>
        <Form form={form} name="user-info" className={styles.formWrapper}>
          <Row className={styles.section} gutter={[12, 12]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 7 }}
              lg={{ span: 7 }}
              className={styles.imageHeader}
            >
              <ImageThumbnailRatio src={imageSignUp} ratio="5684:4000" />
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} offset={1}>
              <Row gutter={[4, { xs: 6, sm: 6, md: 10, lg: 10 }]}>
                <Col span={24}>
                  <Form.Item
                    name="userName"
                    rules={[
                      {
                        required: true,
                        message: 'Nhập họ tên',
                      },
                    ]}
                  >
                    <Input placeholder="Họ tên" className={styles.inputForm} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="newUserEmail"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (validateEmail(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Email không đúng!'));
                        },
                      }),
                    ]}
                  >
                    <Input placeholder="Email" className={styles.inputForm} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <div className={styles.selectRebatePerUnitLandingPage}>
                    <div className={styles.selectUnit}>
                      <Form.Item name="phoneCode">
                        <Select
                          onDropdownVisibleChange={(open) => setDropDownOpen(open)}
                          suffixIcon={
                            <div className={styles.arrowFlipWrapper}>
                              <ArrowFlip open={dropDownOpen} />
                            </div>
                          }
                          popupClassName={styles.dropDownMenu}
                        >
                          {PHONE_CODE.map((item, index) => (
                            <Select.Option value={item.code} key={index}>
                              {item.code} <span className={styles.countryName}>{item.name}</span>
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className={styles.phoneNumberForm}>
                      <Form.Item
                        name="phone"
                        rules={[
                          () => ({
                            validator(_, value) {
                              if (!value)
                                return Promise.reject(new Error('Vui lòng nhập số điện thoại!'));

                              if (validatePhoneNumber(value)) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Số điện thoại không hợp lệ!'));
                            },
                          }),
                        ]}
                      >
                        <Input className={styles.inputFormUnitValue} />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="province"
                    rules={[
                      {
                        required: true,
                        message: 'Chọn tỉnh',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Chọn tỉnh"
                      defaultActiveFirstOption
                      className={styles.inputForm}
                    >
                      {provinces.map((province) => (
                        <Select.Option key={province}>{province}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              className={styles.userIntent}
            >
              <div>
                <div className={styles.offerHeading}>Chọn Khuyến mãi bạn muốn nhận</div>
                <Form.Item
                  name="services"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn dịch vụ bạn đang quan tâm!',
                    },
                  ]}
                >
                  <Checkbox.Group>
                    {servicesOptions.map((item) => {
                      return (
                        <div key={item.code}>
                          <Checkbox value={item.code}></Checkbox>
                          {serviceNameMapper[item.code]}
                        </div>
                      );
                    })}
                  </Checkbox.Group>
                </Form.Item>
              </div>
              <div>
                <div className={styles.offerHeading}>Thị trường quan tâm</div>
                <Form.Item
                  name="market"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn thị trường bạn đang quan tâm!',
                    },
                  ]}
                >
                  <Checkbox.Group>
                    {marketOptions.map((item) => {
                      return (
                        <div key={item.code}>
                          <Checkbox value={item.code}></Checkbox>
                          <VnrLink to={item.link} className={styles.optionName}>
                            {item.name[locale]}
                          </VnrLink>
                          <br />
                        </div>
                      );
                    })}
                  </Checkbox.Group>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Col>
            <Button onClick={handleSaveInfo} className={styles.buttonRegister} type="primary">
              <span className={styles.textLogin}>ĐĂNG KÝ</span>
            </Button>
          </Col>
        </Form>
      </div>
    </div>
  );
};

export default ProfileInformationHomepage;
