import LogoVnrWhiteCn from "@components/Icons/logoVnrWhite_cn";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  home: {
    title: 'VnRebates - 深入了解外汇、加密货币和商品市场的交易知识',
    description: '亚洲排名第一的外汇现金返还生态系统：免费深度交易课程、交易信号和许多其他累积奖励',
    logo: '/icons/logoVnrHomePage_cn.svg',
    logoWhiteFooter: <LogoVnrWhiteCn width={200} />,
    logoWhiteFooterMobile: <LogoVnrWhiteCn width={150} />,
    forexBrokerListTitle: '外汇经纪人回扣名单',
    cryptoBrokerListTitle: '加密货币经纪人',
    tradeFundListTitle: '交易基金',
    viewMore: '查看更多',
    topBrokerPost: '顶级经纪人名单',
    paymentMethod: '回扣支付方式',
    homeSliderForeign: 'https://agileops-gafv-dev-videos.s3.amazonaws.com/bannerAds/slider%20cn.jpg',
    bannerAcademy: "https://d3gxgptuxgz1ri.cloudfront.net/bannerAds/Contest%201080%20%28300%20x%20190%20px%29%20%281%29.png",
    bannerSignal: "https://agileops-gafv-dev-videos.s3.amazonaws.com/bannerAds/signal%20cn.jpg"
  },
  homepageBrokerList: {
    openAccount: '登录',
    rebateRate: '回扣率',
    viewMore: '查看全部',
    feeReduction: '减费',
    noted: '笔记',
    exchange: '去中心化交易所',
    maxLeverage: '最大杠杆',
    transactionFees: '交易费用'
  },
  dashboard: {
    rewardTreeTitle: '成为VnRebates会员的好处',
    title: '成为VnRebates会员的好处',
    hello: '您好',
    friend: '',
    totalRebate: '收到的回扣总额',
    rebate: '回扣',
    ctv: '成为CTV并获得额外50%的佣金',
    benefitDescription: '所有 VnRebates 会员都将通过以下方式获得这些好处：',
    quote: 'VnRebates 愿在您的交易之路上陪伴创造价值和可持续的利润',
    rewardPathTitle: '累计赠送',
    reward: '礼物',
    goal: '目标&活动',
    titleRebateLink: '我的帐户回扣',
    linkedHistory: '链接历史',
    noLink: `还没有链接`,
    addBrokerAccountSuccess: 'Success',
    pleaseSelectBroker: '选择经纪人',
    selectBroker: '选择经纪人',
    accountNumber: '帐号',
    addLink: '添加链接',
    confirm: '我同意让 VnRebates 所有者成为我的 IB',
    paymentMethod: '接收付款',
    caution: '注意',
    cautionNote1: 'VnRebates only offers rebates to successfully linked accounts.',
    cautionNote2: 'To receive rebates, please make sure you submit valid personal information and methods of payment prior to the 5th day of a month.',
    cautionNote3: 'Failure to submit all required information will result in your loss of monthly rebates.',
    payRebatesDescription: '上个月的回扣（外汇现金返还）将在每个月的 5 日之前自动支付给您。',
    lastMonthRebate: '月回扣',
    totalReceivedCommission: '收到的总佣',
    totalReceivedRebates: '收到的总回扣',
    statistical: '近12个月回扣统计',
    walletEmail: 'Ngan Luong电子钱包的电子邮件',
    agreed: '同意',
    agencyRegisterTitle: '合作伙伴介绍客户',
    agencyRegister: '登记',
    agencyRegisterParterRequired: '您需要登录才能激活此功能',
    activities: '活动',
    noActivitives: '没有什么活动',
    bannerRebates: "https://agileops-gafv-dev-videos.s3.amazonaws.com/bannerAds/cn-dasboard-rebate-img.png",
    agencyViewStat: '查看您的客户统计数据和佣金',
    agencyLink: '推荐链接',
    copyAgencyLink: '复制链接',
    agencyCode: '推荐码',
    traderPath: '交易者之路',
    manageRebates: '管理回扣',
    lastMonthCompare: '与上月相比下降',
    ctvMobile: '注册机构可获得50%佣金',
    activeDescription: '已付回扣',
    updateAccount: '更新账户'
  },
  avatarDropdown: {
    profile: '个人信息',
    dashboard: '儀表板',
    logout: '登出'
  },
  menu: {
    featurePost: 'FEATURED',
    topicTreeName: '外汇知识树',
    loginButton: '登录',
    dashboard: '儀表板',
    logout: '登出',
    profile: '个人信息',
    signalChanel: '信号通道',
    forex: '外汇'
  },
  layout: {
    breadcrumb: '主页'
  },
  brokerList: {
    forex: '信誉良好的外汇经纪商名单',
  },
  radioBroker: {
    note: '在以下经纪商/经纪人处参与开户和接收回扣时，VnRebates 承诺所有与您直接开户时相同的交易条件。',
    forex: '外汇经纪商',
    crypto: '加密货币经纪人',
    fund: '交易基金',
    securities: '证券公司',
    merchandise: '商品贸易公司',
  },
  forexInfo: {
    reviewPost: '查看 交易平台评估',
    guild: '交易平台入金/出金说明',
    rebateRate: '回扣率',
    viewDetail: '查看详情',
    gold: '黄金',
    fx: '外币',
    index: '指数',
    oil: '石油',
    crypto: '加密货币',
    receiveRebates: '获得回扣',
    register: '注册',
    establish: '成立时间',
    regulated: '管理人',
    viewRegulated: '管理组织',
    model: '类型',
    deposit: '入金',
    minDeposit: '最低入金',
    minVolume: '最小体积',
    maxLeverage: '最大杠杆',
    spread: '点差',
    product: '产品',
    fee: '交易手续费',
    openAccount: '开账户',
    pageTitle: '享负盛名的外汇经纪商名单-VnRebates',
    pageDescription: '来自VnRebates的评估世界上最负盛名的外汇经纪商，包括Exness、ICmarkets、XM、XTB等'
  },
  cryptoPage: {
    title: '享负盛名的加密货币经纪商名单-VnRebates',
    description: '享负盛名的加密货币经纪商名单，提供最完整的信息和最佳交易费回扣'
  },
  tradeFund: {
    title: '全球知名外汇基金名单',
    reviewPost: '世界最佳外汇经纪商名单-VnRebates',
    description: 'VnRebates提供享负盛名的外汇交易基金的详细信息',
    year: '年',
    payoutRatio: '交易员利润率',
    allowBalance: '资金水平',
    subscribeFee: '参会费',
    dailyDrawdown: '每日回撤',
    maxDrawdown: '最大回撤',
    newsTrading: '消息交易',
    demo: '演示',
    vietnameseSupport: '越南语支持'
  },
  slickBroker: {
    text: '开立账户和现金回扣'
  },
  footer: {
    social: '社交媒体',
    category: '类别',
    riskDisclaimer: `风险提示：交易外汇、期货和期权带来高额利润的同时也伴随着许多潜在风险。高杠杆率可以造成与让您盈利一样多的损害。您必须意识到这些风险，并且在交易时必须愿意接受。外汇交易具有高风险，并不适合所有投资者。我们只向投资者提供货币信息。请记住，任何交易系统或方法过去的良好表现并不能保证未来的良好结果。`,
    license: '注册许可：实验性社交网络正在 完成许可程序',
    advertising: '联系广告。',
    copyRight: '版权所有',
    copyRightReserve: '。版权所有'
  },
  awardTarget: {
    becameMember: '成为VnRebates会员的好处',
    awardAchieved: '到达',
    login: '登录',
    startHere: '从这里开始',
    airTagRequire: '达到1,000$回扣',
    airPodRequire: '达到4,000$回扣',
    smartWatchRequire: '达到14,000$回扣',
    macbookRequire: '达成40,000$回扣',
    shRequire: '达到100,000$回扣'
  },
  profile: {
    title: '在VnRebates，有很多金融投资领域的服务，所以请留下正确的信息，以获得VnRebates专家的免费咨询。',
    name: '姓名',
    phone: '电话号码',
    email: '电子邮件',
    province: '选择省份',
    servicesQuestion: '您希望免费获得哪项服务？',
    martkets: '感兴趣的市场',
    buttonText: '接收免费服务',
    guildText: '注册即可免费获得 VnRebates 的“4 门视频课程”（价值 800 美元）'
  },
  videoAcademy: {
    breadcrumbAcademy: '课程',
    title: 'VSA & Volume Master 课程',
    description: '来自VnRebates的VSA&Volume Master-与鲨鱼一起游泳课程是VnRebetes专业交易员多年知识、经验和分析的结晶',
    priceText: '课程价格',
    price: '200 美元（460 万越南盾）',
    paymentMethod: '付款方式',
    banking: '银行转账',
    purchase: '立即学习',
    viewing: '正在查看',
    viewed: '看了',
    free: '免费',
    view: '看法',
    test: '章测试',
    chatSupport: '立即聊天支持',
    supportOpenAcademy: '联系支持 VnRebates 以免费接收课程'
  },
  logoSponsor: {
    text: 'Broker协办的课程：',
    openAccount: '开账户'
  },
  courseInAcademy: {
    title: '知识体系',
    nameCourse1: "VSA & VOLUME MASTER",
    nameCourse2: "精益SMC课程",
    nameCourse3: "供需课程",
    nameCourse4: "威科夫 2.0 课程",
    nameCourse5: "超新星2.0",
    leveCourse1: "与鲨鱼一起游泳",
    leveCourse2: "智能货币跟踪",
    leveCourse3: "了解使用武力的力量",
    leveCourse4:  `现代威科夫的实用性`,
    leveCourse5:  `在正确的区域使用力量立即改变价格`,
    indexCourse1: "视频课程1",
    indexCourse2: "视频课程2",
    indexCourse3: "视频课程3",
    indexCourse4: "视频课程4",
    indexCourse5: "视频课程5"
  },
  popupLogin: {
    benefitTitle: `"登录并获得以下优惠"`,
    benefitName1: '从基础到高级的4门交易课程（售价行动、心理和资本管理、交易量和VSA）',
    benefitName2: '4种市场类型的交易信号通道（股票、商品、外汇、加密货币）',
    benefitName3: '在任何附属交易所获得 40% 的现金回扣',
    benefitName4: '获得更多有价值的礼品',
    or: '或者',
    textBack: '回到登入',
    login: '登录',
    register: '注册',
    loginEmail: '邮箱注册',
    loginPassword: '密码注册',
    loginRePassword: '重新输入密码',
    agencyCode: '推荐码',
    registerAccount: '注册',
    forgotPassword: '忘记密码?',
    checkboxText: '我同意接收VnRebates优惠和教育内容的电子邮件通知'
  },
  popupRegisterAgency: {
    title: '成为“VNREBATES合作伙伴”的条件',
    agreed: '同意以下条件成为VnRebates合作伙伴',
    benefit: '1. 福利：',
    benefit1: '合作伙伴获得的佣金相当于向推荐客户提供的回扣的50%',
    benefit2: '合作伙伴将在每月5日之前同时获得佣金和回扣',
    benefit3: '合作伙伴有权获得VnRebates的相关图片、推荐链接、资源和服务，以便于向潜在客户和市场推广和宣传我们的服务g',
    benefit4: '合作伙伴将获得经纪人最新报价、所有类型的账户、回扣率等信息。。。',
    benefit5: '客户有权换合作伙伴的推荐码，新合作伙伴的推荐码和佣金将在下个月生效。',
    responsibilities: '2. 职责：',
    responsibilities1: '合作伙伴必须积极宣传和推广我们的回扣服务，以接触潜在客户和市场',
    responsibilities2: '合作伙伴在任何情况下都不得使用错误信息来宣传我们的服务',
    responsibilities3: '合作伙伴在任何情况下都不得利用我们的形象和声誉进行非法行为，从而损害我们的信誉',
    responsibilities4: '合作伙伴必须全面负责解决客户与经纪人之间的冲突',
    ourResponsibilities: '3. VnRebates的责任：',
    ourResponsibilities1: 'VnRebates必须根据每个经纪人佣金的现行规定向客户和合作伙伴汇款回扣',
    ourResponsibilities2: 'VnRebates是客户、合作伙伴和经纪人之间解决潜在冲突的中介',
    ourResponsibilities3: '虽然包括在内，但VnRebates在解决客户和推荐经纪人之间的冲突方面不承担任何责任',
    termination: '4. 合作终止：',
    terminationText: '如果合作伙伴违反上述条款，损害客户和VnRebates的利益以及我们的形象，合作伙伴关系将立即终止',
    yes: '同意',
    no: '不同意'
  },
  tutorialTranferIBModal: {
    title: '“已发送帐户信息。',
    text1: '谢谢您！VnRebates已收到账户和经纪人信息，以验证回扣链接。',
    text2: '接下来，请在下面的支持框中完成身份验证过程。',
    support: 'Telegram支持'
  },
  buttonTelegram: '联系我们',
  roadToPro: {
    title: '成为专业交易者的外汇课程-VnRebates',
    description: '专业交易员之路变得毫不费力，这归功于经验丰富的外汇交易大师在VnRebates其他金融通道的帮助下设计的外汇课程'
  },
  rebatePaymentMethod: {
    signUpButton: '免费注册',
    nganluongWallet: 'Nganluong电子钱包',
    usdtWallet: 'USDT电子钱包',
    direct: '直接至交易账户'
  },
  referrals: {
    seoTitle: '介绍客户 | VnRebates',
    title: '优惠 “推荐1位客户-获得2分回扣”',
    description: '通过在VnRebates提供的外汇、股票、商品和衍生交易中推广回扣服务，赚取稳定的被动收入',
    start: '立即开始',
    titleBenefit: '关于我们的推荐计划',
    roleDescription: '每当您使用私人推荐链接将客户推荐给 VnRebates，您可以获得现金的佣金。只要客户的账户有效，交易终身有效',
    referralFriends: '你将获得多少回扣钱',
    firstReason1: '推荐客户获得的回扣相当于经纪人向Vnrebates支付的回扣的40%',
    firstReason2: '您将获得相当于其推荐客户获得的回扣50%的佣金',
    firstReason3: '如果转介客户转介另一位客户，您将获得相当于该客户获得的回扣8.75%的额外佣金',
    referralFriendsHow: '你将如何获得佣金',
    secondReason1: '佣金总额将在每月5日前统计',
    secondReason2: '客户将在每月5日之前同时从个人交易活动中获得佣金和回扣',
    secondReason3: '付款方式: Nganluong电子钱包',
    criteriaWrapper: '我们选择合作经纪人的标准',
    criteriaList: 'FCA、FSA、CySEC等知名监管机构下的国际经纪人',
    criteriaRequire: '拥有优惠交易的经纪人为各种类型的账户提供服务，以满足各种交易员，特别是专业交易员的各种交易策略',
    criteriaTranslate: '经纪人提供细致和专业的越南语支持',
    guildTitle: '客户推荐注册说明',
    register: ' 使用VnRebates注册会员',
    registerGuild: '点击右上角的<strong>“登录”</strong>按钮成为VnRebates的会员',
    login: '登录',
    agencyRegister: '与VnRebates建立合作伙伴关系',
    agencyRegisterGuild: '登录后，在您的个人页面上向下滚动，直到显示推荐部分。搜索“注册合作伙伴关系”选项，然后点击成为我们的合作伙伴，并收到您的个人推荐链接和代码',
    step3: ' 推荐客户获得佣金',
    step3Guild: '通过个人推荐链接或代码向客户推荐Vnrebates提供的回扣服务，以获得佣金',
    step3GuildMore: '点击此处的统计链接客户数量和月度佣金',
    here: '此处',
    step3GuildMoreAndMore: ' 注册Nganluong电子钱包以获得佣金',
    step4: '开始推荐客户',
    contact: '如有任何问题，请随时联系我们',
    principle: 'VnRebates根据以下原则运行',
    principleQuote: '如果你想走得快，一个人走；如果你想走得远，一群人走',
    principleEnding: '我们希望连接IB社区，合作创造价值，同时为您的推荐客户确保可持续的利益。'
  },
  brokerOpenAccountInstruction: {
    step1: '单击下面的选项注册',
    step2: `通过我们的<a href="https://www.facebook.com/Vnrebates" target="_blank">粉丝专页</a>或<a href="https://t.me/vnrebatessupport" target="_blank">Support Telegram </a>提交以下个人信息“姓名-MT4/MT5账号-经纪人”以供验证 `,
    step3: '开始交易并每月从Vnrebates获得回扣',
    fund: `关于如何选择适合基金的免费咨询可通过<a href="https://www.facebook.com/Vnrebates" target="_blank">粉丝专页</a>或<a href="https://t.me/vnrebatessupport" target="_blank">Support Telegram </a>获得`
  },
  landingPageRebates: {
    seoTitle: '什么是金融交易回扣（Rebates)',
    header: ``,
    subHeader: `<span style="font-size: 28px">您知道吗？当IB（介绍经纪人）为trader <br className="hiddenDesktop" />（交易者）推荐产品的时候可以得到佣金</span>`,
    headerContent1: `但是80% 的trader却得不到任何佣金`,
    headerContent2: `这样无利可图的事必须结束了`,
    readMore: '了解更多',
    subHeaderMobile: `<span style="font-size: 28px">您知道吗？当IB（介绍经纪人）为trader <br className="hiddenDesktop" />（交易者）推荐产品的时候可以得到佣金</span>`,
    mobileHeaderContent1: `但是80% 的trader却得不到任何佣金`,
    mobileHeaderContent2: ` 这样无利可图的事必须结束了`,
    titleSection: `什么是金融交易回扣（Rebates）？`,
    sectionText1: '"最简单的是，当您通过VnRebates的IB（介绍经纪人）在股票、商品、forex（外汇）、crypto（加密货币）的Broker（经纪人）开立交易账户时，我们将在您进行交易时从Broker获得佣金。',
    sectionText2: `然后，我们从这些佣金中提取高达80%的回扣给您，这称为回扣（Rebates）。"`,
    titleSection1: '这代表什么？就是',
    itemList1: '交易更多 - 获得更多资金',
    itemList2: '创造更多的被动收入',
    itemList3: '像直接在Broker（经纪人）开帐一样有100%交易条件',
    itemList4: '交易成本抵消',
    itemList5: '基于交易量回扣-尽管输赢',
    itemList6: '为所有股票、商品、forex（外汇）、crypto（加密货币）等交易回扣',
    brokerRebates: 'Broker的现金回扣率',
    brokerRebatesDescription: '交易所支持退款的完整列表',
    forexList: '外汇清单',
    cryptoList: '加密列表',
    securitiesList: '证券清单',
    merchandiseList: '商品清单',
    titleSection2: '申请回扣（Rebates）',
    step1: '注册成为VnRebates会员',
    step2: `点击右上角<b>“登录”</b>按钮成为VnRebates会员`,
    login: '登录',
    step3: '开设交易账户',
    step3Guild: '选择合适的交易所并在下面的经纪人列表中开设交易账户',
    brokerList: '经纪人名单',
    step4: '申请回扣',
    step4Guild: '在经纪人开立账户并验证身份后。请点击“个人资料图片”然后进入“儀表板（Dashboard）”',
    step4Guild1: '在您想要的经纪人申请回扣，并等待我们确认',
    step5: '添加付款方式',
    step5Guild: '"目前，VnRebate 仅支持通过 Ngan Luong 付款，因此如果您没有帐户，请在此处注册Ngan Luong电子钱包帐户。在付款方式部分输入您的电子邮件信息".',
    here: '此处',
    emailMethod: 'Ngan Luong电子钱包的电子邮件',
    step6: '开始交易',
    step6Guild: `"完成这些步骤后，您就可以开始交易了。
     <br />
     VnRebates 将在每月5日前汇总并为您支付回扣"`,
    step6Guild1: `"无论您是刚开户还是已经在上述平台上开户，我们都会给您回扣"`,
    contact: '如有任何问题，请联系我们：',
    fanpage: 'Fanpage',
    telegram: 'Telegram',
    trustUs: '我们想为越南trader（交易员）提供最专业的回扣服务，您的利益就是我们的利益。',
    trustUs1: '希望能与您长期合作。',
    brokerAccount: '经纪商（Broker）的交易账户',
    product: '产品',
    currency: '货币（欧元/美元）',
    gold: '黄金',
    derivative: '衍生',
    merchandise: '商品（咖啡、油……）',
    derivativeSecurities: '股票（衍生）',
    volume: '交易量/月',
    unit: '单元',
    contract: '合同',
    spreadRate: '份额差价/经纪费比率',
    spread: '点差/经纪费 (VND)',
    vnrCommission: 'VnRebates 收取的经纪费',
    sum: '总共',
    customerCommission: '客户收到退款',
    trustedUs: '信任我们',
    exnessCurrency: '2,638.1¥',
    binance: '855.6¥',
    vps: '198.4¥',
    sumTotal: '6,330.2¥',
    sumRebates: '2,532.08¥',
    spreadCurrencyExness: '713¥',
    spreadGoldExness: '1,426¥'
  },
  popupTradingAcademyVideo: {
    buy: '要立即购买或接收免费的精益',
    title: '立即购买视频课程',
    pleaseInbox: ', 请在下面收件箱支持。'
  },
  controlFooter: {
    prevLesson: '上一课',
    nextLesson: '下一课'
  }
}